<template>
  <div>
    <div class="vx-row">
      <div class="vx-col w-full p-0">

        <vs-table :data="dokumenCustomer" stripe :class="{ 'animate-pulse': loading }">
          <template slot="thead">
            <vs-th class="whitespace-no-wrap">Kode Dokumen</vs-th>
            <vs-th class="whitespace-no-wrap">Nama Dokumen</vs-th>
            <vs-th class="whitespace-no-wrap">Diterima</vs-th>
            <vs-th class="whitespace-no-wrap">Tgl Diterima</vs-th>
            <vs-th class="whitespace-no-wrap">File</vs-th>
          </template>
          <template slot-scope="{data}">
            <vs-tr v-for="(item, index) in data" :key="index" class="text-sm">
              <vs-td>{{ item.kode_item_dokumen_customer }}</vs-td>
              <vs-td>{{ item.nama_item_dokumen_customer }}</vs-td>
              <vs-td>
                <feather-icon v-if="item.isDiterima" icon="CheckIcon" svgClasses="h-6 w-6 text-success"/>
                <span v-else>-</span>
              </vs-td>
              <vs-td>{{ item.tgl ? item.tgl : '-' }}</vs-td>
              <vs-td>
                <a :href="item.file_url" target="_blank" v-if="item.file_url">
                  <vs-chip color="primary"><vs-avatar icon-pack="feather" icon="icon-file"/><span>File</span></vs-chip>
                </a>
                <span v-else>-</span>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>

      </div>
    </div>
  </div>
</template>

<script>
import PropertiUnitRepository from '@/repositories/master/properti-unit-repository'

export default {
  name: 'TabProgressDokumenCustomer',
  props: {
    idPropertiUnit: { required: true }
  },
  mounted () {
    this.getDokumenCustomer()
  },
  data () {
    return {
      loading: false,
      dokumenCustomer: []
    }
  },
  methods: {
    getDokumenCustomer () {
      this.loading = true

      PropertiUnitRepository.getDokumenCustomer(this.idPropertiUnit)
        .then(response => {
          this.dokumenCustomer = response.data.data
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
