<template>
  <div>
    <vs-popup class="sm:popup-w-80" title="Detail Unit" :active="isActive" v-on:update:active="emitModalIsActive">
      <!--loading-->
      <div v-if="isLoading" class="w-full px-5 pb-10 pt-5 animate-pulse">
        <vs-progress indeterminate color="primary" :height="1"/>
        <p class="text-center">Loading...</p>
      </div>

      <!--content-->
      <div v-if="!isLoading">
        <div class="p-3">
          <div class="vx-row mb-3">
            <div class="vx-col sm:w-4/12 w-full -mt-1.5">
              <label class="ml-1 text-xs opacity-75 -mt-1">Proyek</label>
              <vs-input class="w-full" :value="propertiUnit.nama_proyek" readonly/>
            </div>
            <div class="vx-col sm:w-2/12 w-full -mt-1.5">
              <label class="ml-1 text-xs opacity-75 -mt-1">Kategori</label>
              <vs-input class="w-full" :value="propertiUnit.nama_properti_kategori" readonly/>
            </div>
            <div class="vx-col sm:w-2/12 w-full -mt-1.5">
              <label class="ml-1 text-xs opacity-75 -mt-1">Tipe</label>
              <vs-input class="w-full" :value="propertiUnit.nama_properti_tipe" readonly/>
            </div>
            <div class="vx-col sm:w-2/12 w-full -mt-1.5">
              <label class="ml-1 text-xs opacity-75 -mt-1">Cluster</label>
              <vs-input class="w-full" :value="propertiUnit.nama_cluster" readonly/>
            </div>
            <div class="vx-col sm:w-2/12 w-full -mt-1.5">
              <label class="ml-1 text-xs opacity-75 -mt-1">No.Unit</label>
              <vs-input class="w-full" :value="propertiUnit.no_unit" readonly/>
            </div>
          </div>
<!--          <div class="vx-row mb-3">-->
<!--            <div class="vx-col sm:w-4/12 w-full -mt-1.5">-->
<!--              <label class="ml-1 text-xs opacity-75 -mt-1">Tipe</label>-->
<!--              <vs-input class="w-full" :value="propertiUnit.nama_properti_tipe" readonly/>-->
<!--            </div>-->
<!--            <div class="vx-col sm:w-4/12 w-full -mt-1.5">-->
<!--              <label class="ml-1 text-xs opacity-75 -mt-1">Cluster</label>-->
<!--              <vs-input class="w-full" :value="propertiUnit.nama_cluster" readonly/>-->
<!--            </div>-->
<!--            <div class="vx-col sm:w-4/12 w-full -mt-1.5">-->
<!--              <label class="ml-1 text-xs opacity-75 -mt-1">No.Unit</label>-->
<!--              <vs-input class="w-full" :value="propertiUnit.no_unit" readonly/>-->
<!--            </div>-->
<!--          </div>-->

          <!--INFO--->
<!--          <div class="vx-row mb-4">-->
<!--            <div class="vx-col sm:w-1/6 w-full">-->
<!--              <span>Proyek</span>-->
<!--            </div>-->
<!--            <div class="vx-col sm:w-5/6 w-full">-->
<!--              <vs-input class="w-full" :value="propertiUnit.nama_proyek" readonly/>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="vx-row mb-4">-->
<!--            <div class="vx-col sm:w-1/6 w-full">-->
<!--              <span>Kategori</span>-->
<!--            </div>-->
<!--            <div class="vx-col sm:w-5/6 w-full">-->
<!--              <vs-input class="w-full" :value="propertiUnit.nama_properti_kategori" readonly/>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="vx-row mb-4">-->
<!--            <div class="vx-col sm:w-1/6 w-full">-->
<!--              <span>Tipe</span>-->
<!--            </div>-->
<!--            <div class="vx-col sm:w-5/6 w-full">-->
<!--              <vs-input class="w-full" :value="propertiUnit.nama_properti_tipe" readonly/>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="vx-row mb-4">-->
<!--            <div class="vx-col sm:w-1/6 w-full">-->
<!--              <span>Cluster</span>-->
<!--            </div>-->
<!--            <div class="vx-col sm:w-5/6 w-full">-->
<!--              <vs-input class="w-full" :value="propertiUnit.nama_cluster" readonly/>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="vx-row mb-4">-->
<!--            <div class="vx-col sm:w-1/6 w-full">-->
<!--              <span>No Unit</span>-->
<!--            </div>-->
<!--            <div class="vx-col sm:w-5/6 w-full">-->
<!--              <vs-input class="w-full" :value="propertiUnit.no_unit" readonly/>-->
<!--            </div>-->
<!--          </div>-->

          <!--options--->
          <div class="vx-row mb-4">
            <div class="vx-col w-full">
              <vs-dropdown vs-trigger-click class="dd-actions cursor-pointer float-right">
                <feather-icon icon="MoreVerticalIcon" svgClasses="h-5 w-5"/>
                <vs-dropdown-menu>
                  <vs-dropdown-item class="p-1" @click="confirmRemoveFromSiteplan"><span class="whitespace-no-wrap text-danger">Hapus Unit Dari Siteplan</span></vs-dropdown-item>
                </vs-dropdown-menu>
              </vs-dropdown>
            </div>
          </div>

          <!--TAB--->
          <div class="vx-row mb-5">
            <vs-divider class="m-0"></vs-divider>
            <vs-tabs v-model="tabActive" alignment="fixed">
              <vs-tab label="Progress Unit">
                <TabProgressUnit :progress-unit="propertiUnit.progress_unit"/>
              </vs-tab>

              <vs-tab label="Dokumen Customer">
                <TabProgressDokumenCustomer :id-properti-unit="idPropertiUnit"/>
              </vs-tab>

              <vs-tab label="Pembayaran">
                <TabProgressPembayaran :id-properti-unit="idPropertiUnit"/>
              </vs-tab>

              <vs-tab label="Dokumen Unit">
                <TabProgressDokumenUnit :id-properti-unit="idPropertiUnit"/>
              </vs-tab>
            </vs-tabs>
          </div>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import PropertiUnitRepository from '@/repositories/master/properti-unit-repository'
import TabProgressUnit from '@/views/pages/proyek/siteplan/tabs/TabProgressUnit'
import TabProgressDokumenCustomer from '@/views/pages/proyek/siteplan/tabs/TabProgressDokumenCustomer'
import TabProgressPembayaran from '@/views/pages/proyek/siteplan/tabs/TabProgressPembayaran'
import TabProgressDokumenUnit from '@/views/pages/proyek/siteplan/tabs/TabProgressDokumenUnit'

export default {
  name: 'SiteplanDetailUnit',
  components: { TabProgressDokumenUnit, TabProgressPembayaran, TabProgressDokumenCustomer, TabProgressUnit },
  props: ['isActive', 'idPropertiUnit'],
  watch: {
    isActive (value) {
      if (value === true) {
        this.getPropertiUnitData()
      }
    }
  },
  data () {
    return {
      tabActive: 0,
      isLoading: false,
      propertiUnit: {}
    }
  },
  methods: {
    getPropertiUnitData () {
      this.isLoading = true

      PropertiUnitRepository.show(this.idPropertiUnit)
        .then(response => {
          this.propertiUnit = response.data.data
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    confirmRemoveFromSiteplan () {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Konfirmasi',
        text: 'Yakin ingin menghapus unit ini dari siteplan?',
        acceptText: 'Hapus',
        cancelText: 'Batal',
        accept: () => { this.removeFromSiteplan() }
      })
    },

    removeFromSiteplan () {
      PropertiUnitRepository.removeCoordinates(this.idPropertiUnit)
        .then(response => {
          this.notifySuccess('Berhasil menghapus unit dari siteplan.')
          this.emitRemovedFromSiteplan()
          this.emitModalIsActive(false)
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    emitRemovedFromSiteplan () {
      this.$emit('removedFromSiteplan', true)
    },

    emitModalIsActive (isActive) {
      this.resetData()
      this.$emit('update:isActive', isActive)
    },

    resetData () {
      Object.assign(this.$data, this.$options.data.call(this))
    }
  }
}
</script>
