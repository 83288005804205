<template>
  <div>
    <div class="vx-row">
      <div class="vx-col w-full p-0">

        <vs-table :data="pembayaran" stripe :class="{ 'animate-pulse': loading }">
          <template slot="thead">
            <vs-th class="whitespace-no-wrap">Tgl Bayar</vs-th>
            <vs-th class="whitespace-no-wrap">Tipe Angsuran</vs-th>
            <vs-th class="whitespace-no-wrap">No Termin</vs-th>
            <vs-th class="whitespace-no-wrap">Persen</vs-th>
            <vs-th class="whitespace-no-wrap">Jml Bayar</vs-th>
            <vs-th class="whitespace-no-wrap">Jml Sisa</vs-th>
            <vs-th class="whitespace-no-wrap">Jml Denda</vs-th>
            <vs-th class="whitespace-no-wrap">Keterangan</vs-th>
          </template>
          <template slot-scope="{data}">
            <vs-tr v-for="(item, index) in data" :key="index" class="text-sm">
              <vs-td>{{ item.tgl_bayar }}</vs-td>
              <vs-td>{{ item.tipe_angsuran }}</vs-td>
              <vs-td>{{ item.no_termin }}</vs-td>
              <vs-td>{{ item.persen }}%</vs-td>
              <vs-td>{{ item.nama_currency }} {{ item.jml_bayar | idr }}</vs-td>
              <vs-td>{{ item.nama_currency }} {{ item.jml_sisa | idr }}</vs-td>
              <vs-td>{{ item.nama_currency }} {{ item.jml_denda | idr }}</vs-td>
              <vs-td>{{ item.keterangan }}</vs-td>
            </vs-tr>
          </template>
        </vs-table>

      </div>
    </div>
  </div>
</template>

<script>
import PropertiUnitRepository from '@/repositories/master/properti-unit-repository'

export default {
  name: 'TabProgressPembayaran',
  props: {
    idPropertiUnit: { required: true }
  },
  mounted () {
    this.getPembayaran()
  },
  data () {
    return {
      loading: false,
      pembayaran: []
    }
  },
  methods: {
    getPembayaran () {
      this.loading = true

      PropertiUnitRepository.getPembayaran(this.idPropertiUnit)
        .then(response => {
          this.pembayaran = response.data.data
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
