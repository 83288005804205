<template>
  <div>
    <div class="vx-row">
      <div class="vx-col w-full p-0">
        <vs-table :data="progressUnit" stripe>
          <template slot="thead">
            <vs-th class="whitespace-no-wrap">Tgl</vs-th>
            <vs-th class="whitespace-no-wrap">Nama</vs-th>
            <vs-th class="whitespace-no-wrap">Bobot</vs-th>
            <vs-th class="whitespace-no-wrap">Progress</vs-th>
            <vs-th class="whitespace-no-wrap">Keterangan</vs-th>
            <vs-th class="whitespace-no-wrap">File</vs-th>
            <vs-th class="whitespace-no-wrap">Gambar</vs-th>
          </template>
          <template slot-scope="{data}">
            <vs-tr v-for="(item, index) in data" :key="index" class="text-sm">
              <vs-td>{{ item.tgl }}</vs-td>
              <vs-td>{{ item.nama_item_progress_pekerjaan }}</vs-td>
              <vs-td>{{ item.bobot }}%</vs-td>
              <vs-td>{{ item.progress }}%</vs-td>
              <vs-td>{{ item.keterangan }}</vs-td>
              <vs-td>
                <a :href="item.file_url" target="_blank" v-if="item.file_url">
                  <vs-chip color="primary"><vs-avatar icon-pack="feather" icon="icon-file"/><span>File</span></vs-chip>
                </a>
              </vs-td>
              <vs-td>
                <img v-if="item.gambar_url" :src="item.gambar_url" @click="showImage(item.gambar_url)" alt="Gambar" class="rounded w-16 h-16 responsive">
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </div>
    </div>

    <ImageViewer ref="viewer"/>
  </div>
</template>

<script>
import ImageViewer from '@/views/components/image-viewer/ImageViewer'

export default {
  name: 'TabProgressUnit',
  components: { ImageViewer },
  props: {
    progressUnit: {
      required: true,
      type: Array,
      default: () => []
    }
  },
  data () {
    return {}
  },
  methods: {
    showImage (image) {
      this.$refs.viewer.show([image])
    }
  }
}
</script>
