<template>
  <div>
    <div class="vx-row">
      <div class="vx-col w-full p-0">
        <vs-table :data="dokumenUnit" stripe>
          <template slot="thead">
            <vs-th class="whitespace-no-wrap">Nama Dokumen</vs-th>
            <vs-th class="whitespace-no-wrap">No Pengajuan</vs-th>
            <vs-th class="whitespace-no-wrap">Tgl Pengajuan</vs-th>
            <vs-th class="whitespace-no-wrap">Estimasi Selesai</vs-th>
            <vs-th class="whitespace-no-wrap">Tgl Selesai</vs-th>
            <vs-th class="whitespace-no-wrap">Status</vs-th>
            <vs-th class="whitespace-no-wrap">File</vs-th>
            <vs-th class="whitespace-no-wrap">Foto Bukti</vs-th>
            <vs-th class="whitespace-no-wrap">Keterangan</vs-th>
          </template>
          <template slot-scope="{data}">
            <vs-tr v-for="(item, index) in data" :key="index" class="text-sm">
              <vs-td>{{ item.nama_item_dokumen_proyek }}</vs-td>
              <vs-td>{{ item.no_pengajuan }}</vs-td>
              <vs-td>{{ item.tgl_pengajuan }}</vs-td>
              <vs-td>{{ item.tgl_est_selesai }}</vs-td>
              <vs-td>{{ item.tgl_selesai ? item.tgl_selesai : '-' }}</vs-td>
              <vs-td>{{ item.status }}</vs-td>
              <vs-td>
                <a :href="item.file_url" target="_blank" v-if="item.file_url">
                  <vs-chip color="primary"><vs-avatar icon-pack="feather" icon="icon-file"/><span>File</span></vs-chip>
                </a>
              </vs-td>
              <vs-td>
                <img v-if="item.foto_bukti_url" :src="item.foto_bukti_url" @click="showImage(item.foto_bukti_url)" alt="Gambar" class="rounded w-16 h-16 responsive">
              </vs-td>
              <vs-td>{{ item.keterangan_pengajuan }}</vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </div>
    </div>

    <ImageViewer ref="viewer"/>
  </div>
</template>

<script>
import ImageViewer from '@/views/components/image-viewer/ImageViewer'
import PropertiUnitRepository from '@/repositories/master/properti-unit-repository'

export default {
  name: 'TabProgressDokumenUnit',
  components: { ImageViewer },
  props: {
    idPropertiUnit: { required: true }
  },
  mounted () {
    this.getDokumenUnit()
  },
  data () {
    return {
      loading: false,
      dokumenUnit: []
    }
  },
  methods: {
    getDokumenUnit () {
      this.loading = true

      PropertiUnitRepository.getDokumenUnit(this.idPropertiUnit)
        .then(response => {
          this.dokumenUnit = response.data.data
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.loading = false
        })
    },

    showImage (image) {
      this.$refs.viewer.show([image])
    }
  }
}
</script>
